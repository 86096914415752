import React from 'react'

import { Layout } from '../../components/auth/Layout'
import { SignInContent } from '../../components/auth/SignInContent'
import { AccountType } from '../../requests'

const SignInPage = () => {
  return (
    <Layout accountType={AccountType.User}>
      <SignInContent accountType={AccountType.User} />
    </Layout>
  )
}

export default SignInPage
export const Head = () => <title>Datacapt - Sign In</title>
